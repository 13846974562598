// Swan Transit

export default {
  settings: {
    disableIframePostMessage: false,
    ctaWhiteBg: "true",
  },
  content: {
    general: {
      clientName: `Swan Transit`,
      healthcheckDevLink: "https://dev.moneyfit.app/swan-transit/",
    },
    heroIntro:
      "A company funded benefit provided by Gallagher, our Benefits Consultant, that encourages you to engage more with your money and help you face your financial future with confidence.",
    modellers: [
      {
        title: "SUPERANNUATION",
        description: "MLC MasterKey Business Super",
        type: "savings",
        url: "https://welcome.mlc.com.au/portal?id=Swan-5f1e0d9e-5ff0-48bc-804b-50f82af43c1c",
        categoryPic: "savings_w.svg",
        bgImage: "savings",
      },
      {
        title: "BUDGETING",
        description: `See the potential of Money Leaks`,
        type: "budgeting",
        url: "https://lifetime.gbsau.com/gallagher-gbs/small-change/",
        categoryPic: "pie_w.svg",
        bgImage: "budgeting",
      },
      {
        title: "DEBT MANAGEMENT",
        description: "Debt Modeller",
        type: "debt",
        url: "https://lifetime.gbsau.com/gallagher/debt-modeller/",
        categoryPic: "debt_w.svg",
        bgImage: "debt",
      },
      // {
      //   title: "SUPERANNUATION",
      //   description: "Lifetime Supermodeller",
      //   type: "budgeting",
      //   url: "https://www.australiansuper.com/",
      //   categoryPic: "pie_w.svg",
      //   bgImage: "supermodeller",
      // },
      {
        title: "SUPERANNUATION",
        description: "Understanding super",
        type: "savings",
        url: "https://lifetime.gbsau.com/gallagher/supermodeller/",
        categoryPic: "savings_w.svg",
        bgImage: "understanding_super",
      },
      // {
      //   title: "INSURANCE",
      //   description: "Insurance Needs",
      //   type: "budgeting",
      //   url: "https://www.australiansuper.com/",
      //   categoryPic: "pie_w.svg",
      //   bgImage: "insurance",
      // },
      {
        heading: "MoneyFit Healthcheck",
        subheading: "Financial Wellbeing",
        description:
          "Uncover your financial tendencies. Explore your unique approach to money management and financial decisionmaking. MoneyFit can help you achieve financial wellbeing. It aims to empower you to feel confident and in control of your finances.",
        link: "LAUNCH MONEYFIT HEALTH CHECK",
        categoryPic: "owl.png",
        moneyFit: true,
        type: "budgeting",
      },
    ],
  },

  analytics: {
    googleTrackingId: "G-C36DXJ002S",
    enableClarity: true,
    clarityTrackingId: "mc5eqdf2xd",
  },
};
